.mainDiv{
    margin: auto;
    max-width: 50%;
    text-align: center;
    color: #4DB5FF;
    font-family: 'Poppins', sans-serif;
}

.mainDiv img{
    width: 100%;
}