.mainDiv{
    margin: 5rem auto;
    color: #4DB5FF;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}

.inputBox{
    margin: 2rem;
    font-family: 'Poppins', sans-serif;
}

.submitButton{
    margin: .5rem;
    background-color: #4DB5FF;
    color: #1F1F38;
    outline: none;
    border: none;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
}