.mainDiv{
    color: #FFFFFF;
    max-width: 90%;
    margin: auto;
    padding: 2rem;
    font-family: 'Inter', sans-serif;
}

.greetings h1{
    font-size: 1.5rem;
    font-family: 'Poppins', sans-serif;
}

.greetings p{
    font-size: 1rem;
}

.diaz{
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.diaz p{
    font-size: 1rem;
    margin-bottom: .5rem;
}

.diaz iframe{
    max-width: 100%;
}

.location{
    border: #FFFFFF 2px solid;
    border-radius: 8px;
    padding: 2rem;
    display: flex;
    margin-top: 2rem;
    align-items: center;
    gap: 20%;
}

.location h2{
    font-size: 1.1rem;
}

.location p{
    font-size: .9rem;
    color: #FFFFFF;
}

.location a{
    color: #4DB5FF;
    text-decoration: none;
    font-size: .9rem;
}

.location a:hover{
    color: #FFFFFF;
}

.map{
    border: #4DB5FF 2px solid;
    border-radius: 8px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .location{
        flex-direction: column;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .location{
        flex-direction: column;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .location{
        flex-direction: row;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .location{
        flex-direction: row;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .location{
        flex-direction: row;
    }
}