@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

.mainHeading{
    margin: 4rem 4rem 0 4rem;
    text-align: center;
    padding: 3rem 1rem 0 1rem;
}

.mainHeading h1{
    font-weight: bold;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    margin: 0;
}

.mainHeading p,h3{
    font-family: 'Poppins', sans-serif;
    color: #4DB5FF;
}

.mainHeading h2{
    font-family: 'Poppins', sans-serif;
    color: #4DB5FF;
}

.mainHeading p{
    color: #FFFFFF;
}
/* 
.mainHeading h3{
    font-size: 1.5rem;
} */

.image{
    margin: 3rem auto;
}

.image > img{
    width: 100%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mainHeading h1{
        font-size: 2rem;
    }
    
    .mainHeading h2{
        font-size: .6rem;
    }
    
    .mainHeading p{
        font-size: .6rem;
    }
    
    .mainHeading h3{
        font-size: 1.1rem;
    }

    .image{
        width: 60%;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .mainHeading h1{
        font-size: 2rem;
    }
    
    .mainHeading h2{
        font-size: .6rem;
    }
    
    .mainHeading p{
        font-size: .6rem;
    }
    
    .mainHeading h3{
        font-size: .8rem;
    }

    .image{
        width: 60%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .mainHeading h1{
        font-size: 2.5rem;
    }
    
    .mainHeading h2{
        font-size: .7rem;
    }
    
    .mainHeading p{
        font-size: .5rem;
    }
    
    .mainHeading h3{
        font-size: 1rem;
    }

    .image{
        width: 30%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .mainHeading h1{
        font-size: 3.5rem;
    }
    
    .mainHeading h2{
        font-size: 1rem;
    }
    
    .mainHeading p{
        font-size: .8rem;
    }
    
    .mainHeading h3{
        font-size: 1.5rem;
    }

    .image{
        width: 25%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .mainHeading h1{
        font-size: 3.5rem;
    }
    
    .mainHeading h2{
        font-size: 1rem;
    }
    
    .mainHeading p{
        font-size: .8rem;
    }
    
    .mainHeading h3{
        font-size: 1.5rem;
    }

    .image{
        width: 25%;
    }
}