.MainDiv{
    margin: auto;
    text-align: center;
    width: 80%;
    justify-content: center;
}

.MainDiv{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.LinkText{
    color: #FFFFFF;
    text-decoration: none;
    margin: 100px 0;
}

.LinkText:hover{
    color: #4DB5FF;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .MainDiv{
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    
    .MainDiv{
        font-size: .8rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .MainDiv{
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
    }
    
    .MainDiv{
        font-size: .8rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .MainDiv{
        display: grid;
        grid-template-columns: repeat(2, 30%);
        gap: 1.2rem;
    }
    
    .MainDiv{
        font-size: 1rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .MainDiv{
        display: grid;
        grid-template-columns: repeat(2, 30%);
        gap: 1.2rem;
    }
    
    .MainDiv{
        font-size: 1.2rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .MainDiv{
        display: grid;
        grid-template-columns: repeat(2, 30%);
        gap: 1.2rem;
    }
    
    .MainDiv{
        font-size: 1.2rem;
    }
}